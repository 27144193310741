.App {
  overflow: auto;
  background: #f7f7f9;
  font-size: 18px;
  font-weight: 300;
  text-align: justify;
}

.App header {
  width: 800px;
  margin: 32px auto;
}

.App .logo {
  display: block;
  position: relative;
  height: 180px;
  overflow: hidden;
}

.App .logo img {
  position: absolute;
  top: -140px;
  width: 800px;
}

.App .nav {
  margin-top: 16px;
  display: flex;
}

.App .nav a {
  padding: 10px;
  line-height: 32px;
}

.App .nav a.active {
  text-decoration: none;
}

.App .main {
  margin: 0 auto 32px;
  width: 800px;
  padding: 32px;
  background: #ffffff;
}

.App a {
  color: #4BBFE9;
  text-decoration: underline;
}

.App a:hover {
  color: #97DAF2;
  text-decoration: none;
}

.App .copyright {
  text-align: center;
  font-size: 14px;
  margin-bottom: 32px;
}