.Writers {
  display: flex;
}

.Writers .list {
  width: 240px;
  text-align: left;
}

.Writers .writer {
  display: block;
  padding: 8px;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}

.Writers .writer.active {
  background-color: #f7f7f9;
}

.Writers .writer:hover {
  color: #000;
  background-color: #f7f7f9;
}

.Writers .fio {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Writers .separator {
  width: 8px;
  background-color: #f7f7f9;
}

.Writers .content {
  padding-left: 32px;
  width: 520px;
}

.Writers .content h2 {
  margin-top: 0;
}

.Writers .content img {
  width: 50%;
}