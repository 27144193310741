.About .book {
    display: block;
    color: #000;
    text-decoration: none;
}

.About .book:hover h2 {
    color: #555;
}

.About .book:hover img {
    opacity: 0.7;
}

.About img {
    width: 100%;
}
